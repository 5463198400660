import { Environment } from '../types/environment/Environment';
import ENVIRONMENT_VARIABLES from '../constants/envVariables';

export const getEnvironment = () => {
  const hostname = window.location.hostname;

  if (hostname.indexOf('localhost') > -1 || hostname.indexOf('10.0.2.2') > -1) {
    return Environment.LOCAL;
  }
  if (
    hostname.indexOf('-np') > -1 ||
    hostname.indexOf('np-') > -1 ||
    hostname.indexOf('-np-') > -1
  ) {
    return Environment.TEST;
  }
  return Environment.PROD;
};

export const isProd = () => getEnvironment() === Environment.PROD;
export const isTest = () => getEnvironment() === Environment.TEST;
export const isLocal = () => getEnvironment() === Environment.LOCAL;
export const isNonProd = () => getEnvironment() === Environment.TEST;

export const getEnvVariable = (key: string) => {
  const variablesMap = ENVIRONMENT_VARIABLES[key];

  switch (getEnvironment()) {
    case Environment.LOCAL:
      return variablesMap.local
    case Environment.TEST:
      return variablesMap.test;
    default:
      return variablesMap.prod;
  }
};
