export enum UserSearchParameter {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  USERNAME = 'USERNAME',
}

export enum ApplicationSearchParameter {
  DENTALPROVIDER = 'DENTALPROVIDER',
  MYLINCOLNPORTAL = 'MYLINCOLNPORTAL',
  ALL = 'ALL',
}

export enum CustomerSearchParameter {
  CUSTOMERID = 'CUSTOMER_ID',
  CUSTOMERNAME = 'CUSTOMER_NAME',
}
